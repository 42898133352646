import router from '../../../../router'

export default {
    name: 'WarrantCalculator',
    created () {
        // this.$$.navbarAnimation()
        this.$nextTick(function () {
            this.ariseCombobox('#comboboxWarrants')
            // this.ariseCombobox('#comboboxWarrants2')
            // 获取ric
            this.getRiclist()
        })
    },
    mounted () {
        this.$nextTick(function () {

        })
    },
    data () {
        return {
            ricData: [],
            CalculatorData: [],
            exchangeratio: [],
            UnderlingTargetPrice: '',
            ResetUnderlingTargetPrice: '',
            UnderlingTargetPricecount: '',
            UnderlingTargetPricePercent: '',
            exercisePrice: '',
            CurrentWarrantBidV: '',
            CalBid: '',
            CurrencyAgain: '',
            Currency: '',
            newCalBid: '',
            CurrentWarrantBidVChange: '',
            date: '',
            convRatio: '',
            negitive: true,
            newnegitive: true,
            ricNmae: '',
            datepicker: true,
            TotalInput: 0
        }
    },
    computed: {

    },
    watch: {

    },
    methods: {
        /* 获取ric list */
        getRiclist: function () {
            $.ajax({
                type: 'GET',
                url: this.$$.mibb + 'GetRICList',
                data: { token: 'webkey' },
                dataType: 'json',
                success: (data) => {
                    if (data.status === 'SUCCESS') {
                        this.ricData = data.ric
                        this.ricNmae = this.ricData[0].ric
                        this.GetMarketData(this.ricData[0].ric)
                    }
                },
                error: function (error) {
                    console.log(error)
                }
            })
        },
        // 接收Marke数据
        GetMarketData: function (ric) {
            this.datepicker = false
            $.ajax({
                type: 'POST',
                url: this.$$.mibb + 'GetMarketData',
                dataType: 'json',
                data: { token: 'webkey', type: 'warrantdata', ric: ric },
                success: (data) => {
                    if (data.status === 'SUCCESS') {
                        console.log(data);

                        this.CalculatorData = data[ric]
                        this.exchangeratio = data.exchangeratio
                        this.UnderlingTargetPrice = this.$$.formatNumber(data[ric].underlying_bid, 0, 0)
                        this.ResetUnderlingTargetPrice = this.$$.formatNumber(data[ric].underlying_bid, 0, 0)
                        this.UnderlingTargetPricecount = this.$$.reservedNumber(data[ric].underlying_bid)
                        this.UnderlingTargetPricePercent = 0
                        this.exercisePrice = data[ric].exercise_price_cal
                        $('.ui-widget').find('input').val(data[ric].dsply_nmll + ' (' + data[ric].ticker + ')')
                        this.CalculatorData.issuer_name = this.CalculatorData.issuer_name.replace('MACQ', 'Macquarie').toUpperCase() == 'MSI' ? 'Maybank' : this.CalculatorData.issuer_name.replace('MACQ', 'Macquarie').toUpperCase()
                        this.Currency = this.$$.curreryRetrun(data[ric].currency)
                        this.CurrencyAgain = this.$$.curreryRetrun(data[ric].currency)
                        this.CurrentWarrantBidV = (this.$$.curreryRetrun(data[ric].currency) == 'IDR' ? 'Rp' : this.$$.curreryRetrun(data[ric].currency)) + ' ' + this.$$.formatNumber(data[ric].BID, 0, 0)
                        this.CurrentWarrantBidVChange = `${this.$$.changeRate(data[ric].BID_PCTCHNG)} %`
                        this.negitive = this.$$.changeRate(data[ric].BID_PCTCHNG) >= 0
                        this.date = new Date().toDateString()
                        this.convRatio = data[ric].conv_ratio_cal + ' : 1'
                        this.CalBid = this.calculatePrice()
                        this.newCalBid = '0 %'
                        this.bid = data[ric].BID
                        this.formControl(data[ric].maturity)


                    }
                },
                error: function (jqXHR) {

                }
            })
        },
        Reset: function () {
            this.UnderlingTargetPrice = this.ResetUnderlingTargetPrice
            this.UnderlingTargetPricePercent = 0
            $('.form-control').datepicker('setDate', new Date(new Date().toDateString()))

            this.Currency = this.CurrencyAgain
            this.CalBid = this.calculatePrice()
            this.newCalBid = '0 %'
        },
        // Underling Target Price input方法
        UnderlingTargetInput: function (e) {
            this.UnderlingTargetPrice = e.target.value
            this.UnderlingTargetPricePercent = this.$$.formatNumber((e.target.value - this.UnderlingTargetPricecount) / this.UnderlingTargetPricecount * 100, 2, 0)
            this.CalBid = this.calculatePrice()
            this.newCalBid = this.$$.formatNumber((parseFloat(this.CalBid) - parseFloat(this.bid)) / parseFloat(this.bid) * 100, 1, 0) + ' %'
            this.newnegitive = parseFloat(this.CalBid) - parseFloat(this.bid) >= 0
            console.log(this.UnderlingTargetPricePercent.toString().length)
        },
        UnderlingTargetPercentInput: function (e) {
            let num = 0
            this.UnderlingTargetPricePercent = e.target.value.replace(/[^\d\.-]/g, '')
            this.UnderlingTargetPrice = this.$$.formatNumber(Number(this.UnderlingTargetPricecount) * (1 + Number(this.UnderlingTargetPricePercent) / 100), 2, 0)
            this.CalBid = this.calculatePrice()
            this.newCalBid = this.$$.formatNumber((parseFloat(this.CalBid) - parseFloat(this.bid)) / parseFloat(this.bid) * 100, 1, 0) + ' %'
            this.newnegitive = parseFloat(this.CalBid) - parseFloat(this.bid) >= 0
            if (e.target.value.length == 1) {
                num = 20
            } else {
                num = e.target.value.length * 10 + 10
            }
            $('#WarrantCalculator').find('.percent').find('i').css({
                left: num + 'px'
            })
        },
        // 日历选择器的方法
        formControl: function (maturity) {
            console.log(new Date(maturity))
            const _this = this
            this.datepicker = true
            setTimeout(function () {
                $('.form-control')
                    .datepicker({
                        autoclose: true,
                        dateFormat: 'dd M y',
                        minDate: new Date(new Date().toDateString()),
                        maxDate: new Date(maturity),
                        onSelect: function (dateText, inst) {
                            _this.date = dateText
                            _this.CalBid = _this.calculatePrice()
                            _this.newCalBid = _this.$$.formatNumber((parseFloat(_this.CalBid) - parseFloat(_this.bid)) / parseFloat(_this.bid) * 100, 1, 0) + ' %'
                            _this.newnegitive = parseFloat(_this.CalBid) - parseFloat(_this.bid) > 0
                        }
                    })
                    .datepicker('setDate', new Date(new Date().toDateString()))
            }, 10)
        },
        // 时间计算
        intervalDate: function (old, news) {
            return ((old - news) / (1 * 24 * 60 * 60 * 1000)) / 365
        },
        // 计算值
        calculatePrice: function () {
            let underlyingPriceCal, exercisePriceCal, num

            underlyingPriceCal = parseFloat(this.UnderlingTargetPrice)
            exercisePriceCal = parseFloat(this.exercisePrice)
            $.each(this.exchangeratio, (i, c) => {
                if (this.CalculatorData.underlying_curr == c.currency) {
                    underlyingPriceCal = parseFloat(this.UnderlingTargetPrice) / parseFloat(c.rate)
                    exercisePriceCal = parseFloat(this.exercisePrice) / parseFloat(c.rate)
                }
            })

            num = this.$$.blackScholes(
                underlyingPriceCal,
                exercisePriceCal,
                this.intervalDate(new Date(this.CalculatorData.maturity), new Date(this.date)),
                parseFloat(this.CalculatorData.v_implied_volatility) / 100,
                0.03,
                this.CalculatorData.type
            )
            return this.$$.formatNumber(num / this.convRatio, 0, 0)
        },
        // to StructureYourWarrant页面
        toStructureYourWarrant: function () {
            this.$router.push({ name: 'Structure Your Warrant', params: { data: this.CalculatorData } })
            localStorage.setItem('StructureYourName', JSON.stringify(this.CalculatorData))
        },
        // 出现combobox
        ariseCombobox: function (id) {
            const _this = this
            this.$$.jqcombobox(id)
            $(id).combobox({
                select: function (event, ui) {
                    this.ricNmae = this.value
                    _this.GetMarketData(this.value)
                }
            })
            $(id).parent().find('input').attr('placeholder', this.$t('WCr.SW'))
        }
    }
}
